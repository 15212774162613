<template>
  <div>
    <b-jumbotron
      bg-variant="info"
      text-variant="light"
      :style="`background-image: url(${DashboardBackgroundImg}); background-size: cover; background-position: center`"
    >
      <div class="container">
        <h1 class="display-3">User Profile</h1>
        <p class="lead" v-html="profileMsg"></p>
      </div>
    </b-jumbotron>

    <div class="container">
      <div>
        <KTCard title="User Profile">
          <template v-slot:toolbar> </template>

          <template v-slot:body>
            <pre class="d-none">{{ UserData }}</pre>
            <b-row>
              <b-col cols="2" class="text-right">
                <label class="font-weight-bold">Username</label>
              </b-col>
              <b-col>{{ UserData.username }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="2" class="text-right">
                <label class="font-weight-bold">Email</label>
              </b-col>
              <b-col>{{ UserData.email }}</b-col>
            </b-row>
            <b-row v-if="UserData.company">
              <b-col cols="2" class="text-right">
                <label class="font-weight-bold">Company Name</label>
              </b-col>
              <b-col>{{ UserData.company.company_name }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="2" class="text-right">
                <label class="font-weight-bold">Last Login</label>
              </b-col>
              <b-col>{{ UserData.last_login }}</b-col>
            </b-row>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import UserService from "@/core/services/user.service";

export default {
  name: "blank",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "User Profile" }]);
  },
  data() {
    return {
      profileMsg:
        "People’s names are their brands, so are their nicknames. Give users a chance to promote it. Not only should you introduce a ‘nickname’ field to this page, but you should also include the nickname in the URL. This way you will gain customers’ loyalty and boost traffic to your site (especially if a famous person has an account on it).",
    };
  },

  methods: {},
  computed: {
    DashboardBackgroundImg() {
      return "/assets/img/placeholders/dashboard_trainee_bg01.jpg";
    },
    UserData() {
      let userData = JSON.parse(UserService.getUserData());
      return userData;
    },
  },
};
</script>
